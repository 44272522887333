import en from './en.json';
import es from './es.json';
import fr from './fr.json';
import pt from './pt.json';

export const defaultLocale = 'fr';

export const languages = {
  en,
  es,
  fr,
  pt,
};
