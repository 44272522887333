<template>
  <v-carousel
    cycle
    :interval="cycleInterval"
    hide-delimiters
    :show-arrows="false"
    height="100%"
  >
    <v-carousel-item
      v-for="(item,i) in items"
      :key="i"
      :src="item.src"
    ></v-carousel-item>
  </v-carousel>
</template>
<script>
import Image1 from '../assets/Bandeau_Accueil_Voiture_Feu.jpg';
import Image2 from '../assets/Drone_Recreatif.jpg';
import Image3 from '../assets/homme_centrale_surveillance.jpg';
import Image4 from '../assets/Securite_Moniteurs.jpg';

export default {
  name: 'BgSlideshow',
  data() {
    return {
      items: [
        { src: Image1 },
        { src: Image2 },
        { src: Image3 },
        { src: Image4 },
      ],
      cycleInterval: 6000,
    };
  },
};
</script>
