<template>
  <v-card class="pa-4 text-center" flat>
    <v-card-title class="green--text pa-0 mb-4 justify-center text-h6 text-lg-h5 font-weight-black">
      Connected to room: {{ formattedPhoneNumber }}
    </v-card-title>
    <div class="row remote_video_container">
      <div id="remoteTrack"></div>
    </div>
    <v-card-actions class="justify-center">
      <v-btn fab dark color="error" @click="$emit('hangup')">
        <v-icon>mdi-phone-hangup</v-icon>
      </v-btn>
      <v-btn v-if="!isMuted" class="mx-2" fab color="green" dark @click="$emit('muted', true)">
        <v-icon dark>mdi-microphone</v-icon>
      </v-btn>
      <v-btn v-else class="mx-2" fab color="gray" dark @click="$emit('muted', false)">
        <v-icon dark>mdi-microphone-off</v-icon>
      </v-btn>
      <v-btn class="mx-2" fab dark color="gray" @click="setVideoFullscreen">
        <v-icon>mdi-fullscreen</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'VideoCard',
  props: {
    phone: String,
    isMuted: Boolean,
  },
  computed: {
    formattedPhoneNumber() {
      if (this.phone) {
        return `${this.phone.substring(0, 3)}-${this.phone.substring(3, 6)}-${this.phone.substring(6)}`;
      }
      return this.phone;
    },
  },
  methods: {
    setVideoFullscreen() {
      const { userAgent } = navigator;
      const videoContainer = document.getElementById('remoteTrack');
      if (userAgent.indexOf('Chrome') !== -1) {
        // Chrome
        videoContainer.requestFullscreen();
      } else if (userAgent.indexOf('Firefox') !== -1) {
        // Firefox
        videoContainer.mozRequestFullScreen();
      } else if (userAgent.indexOf('Safari') !== -1) {
        // Safari
        videoContainer.webkitRequestFullscreen();
      } else if (userAgent.indexOf('Edge') !== -1) {
        // Microsoft Edge (Chromium-based)
        videoContainer.msRequestFullscreen();
      } else {
        videoContainer.requestFullscreen();
      }
    },
  },
};
</script>

<style lang="scss">
.remote_video_container {
  left: 0;
  margin: 0;
  min-height: 500px;
  #remoteTrack {
    margin: 0 auto;
    video {
      min-height: 300px;
      height: 100%;
      width: 100%;
    }
  }
}
</style>
