<template>
  <div class="rounded tooltip-container pa-1">
    <v-tooltip bottom>
      <template #activator="{on, attrs}">
        <v-btn
          variant="flat"
          density="compact"
          small
          v-bind="attrs"
          v-on="on"
          @click.prevent="copyCoordinates(`${lat}, ${lng}`)"
        >
          {{ lat }}, {{ lng }}
          <v-icon class="ml-3" size="20">{{ copyIcon }}</v-icon>
        </v-btn>
      </template>
      <span>Copy GPS coordinates to clipboard</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template #activator="{on, attrs}">
        <v-btn
          small
          variant="flat"
          density="compact"
          v-bind="attrs"
          v-on="on"
          @click.prevent="navigateToGMap"
        >
          <v-icon size="20">{{ directionIcon }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('navigate_coordinates' ) }}</span>
    </v-tooltip>
    <input
      ref="gpsCoordinates"
      type="hidden"
      v-model="coordinates"
      tabindex="-1"
      aria-hidden="true"
    />
  </div>
</template>

<script>
import { mdiContentCopy, mdiDirections } from '@mdi/js';

export default {
  name: 'CopyCoordinates',
  data: () => ({
    copyIcon: mdiContentCopy,
    directionIcon: mdiDirections,
    coordinates: '',
  }),
  props: {
    lat: {
      type: Number,
      default: 0,
    },
    lng: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    navigateToGMap() {
      window.open(`http://www.google.com/maps/place/${this.lat},${this.lng}/@${this.lat},${this.lng},17z/data=!3m1!1e3`, '_blank');
    },
    async copyCoordinates(c) {
      try {
        const type = 'text/plain';
        const blob = new Blob([c], { type });
        const data = new window.ClipboardItem({ [type]: blob });
        await navigator.clipboard.write([data]);
      } catch (error) {
        this.copyCoordinatesFallback(c);
      }
    },
    copyCoordinatesFallback(c) {
      this.coordinates = c;
      this.$refs.gpsCoordinates.setAttribute('type', 'text');
      this.$refs.gpsCoordinates.select();
      try {
        const successful = document.execCommand('copy');
        console.log(successful);
      } catch (err) {
        console.log(err);
      }
      this.$refs.gpsCoordinates.setAttribute('type', 'hidden');
      window.getSelection().removeAllRanges();
    },
  },
};
</script>

<style scoped>
  .tooltip-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
</style>
