<template>
  <v-card class="pa-4 w-opacity text-center" :disabled="isLoading" flat>
    <v-card-title class="red--text pa-0 mb-4 justify-center text-h5 text-lg-h4 font-weight-black">
      {{ $t('title') }}
    </v-card-title>
    <v-row justify="center">
      <v-col cols="10" md="6">
        <v-text-field
          v-model="phone"
          ref="inputText"
          color="grey darken-1"
          background-color="grey"
          class="pb-0 centered-input"
          :label="$t('input-label')"
          :placeholder="$t('input-label')"
          :rules="[rules]"
          outlined
          single-line
          dense
          @keyup.enter="stripPhoneNumber"
        />
      </v-col>
    </v-row>
    <v-card-actions class="justify-center">
      <v-btn color="error" @click="stripPhoneNumber">{{ $t('submit-button') }}</v-btn>
    </v-card-actions>
    <v-alert v-if="alertText" :type="alertType" class="mt-4" border="left" text dense dismissible>
      {{ alertText }}
    </v-alert>
  </v-card>
</template>

<script>
export default {
  name: 'PhoneForm',
  props: {
    alertText: String,
    alertType: String,
    isLoading: Boolean,
  },
  data() {
    return {
      phone: null,
      inputText: null,
      rules: (value) => {
        const pattern = /^\d{3}-?\d{3}-?\d{4}$/;
        return pattern.test(value) || this.$t('input-validation');
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.inputText.focus();
    });
  },
  methods: {
    stripPhoneNumber() {
      const stripped = this.phone.replace(/\D/g, '');
      this.$emit('phoneInput', stripped);
    },
  },
};
</script>

<style lang="scss">
.v-alert__content {
  word-break: break-all;
}
.w-opacity {
  background-color: rgba(0,0,0,0.5) !important;
}
.centered-input input {
  text-align: center;
}
.centered-input label {
  left: 50% !important;
  transform: translateX(-50%);
}
.centered-input .error--text {
  text-align: center;
}
</style>
