<template>
  <v-container>
    <v-row justify="center" align="center">
      <v-col v-show="isConnected" cols="12">
        <v-row justify="center" align="center">
          <v-col cols="12" md="6">
            <GoogleMaps
              :phonenumber="phone"
              :is-connected="isConnected"
              :position="devicePosition"
            />
          </v-col>
          <v-col cols="12" md="6">
            <video-card
              :phone="phone"
              :is-muted="isMuted"
              @hangup="leaveRoom"
              @muted="toggleAudio"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="8" lg="7">
        <phone-form
          v-show="!isConnected"
          :is-loading="isLoading"
          :alert-text="alertMessage.text"
          :alert-type="alertMessage.type"
          @phoneInput="connectToVideo"
        />
      </v-col>
      <v-progress-circular v-if="isLoading" indeterminate size="60" class="loading" />
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import Twilio from 'twilio-video';
import VideoCard from '../components/VideoCard.vue';
import PhoneForm from '../components/PhoneForm.vue';
import GoogleMaps from '../components/GoogleMaps.vue';

export default {
  name: 'HomeView',
  components: {
    GoogleMaps,
    VideoCard,
    PhoneForm,
  },
  data() {
    return {
      phone: null,
      isLoading: false,
      room: null,
      accessToken: null,
      isMuted: false,
      alertMessage: {
        type: 'success',
        text: '',
      },
      streamData: null,
    };
  },
  mounted() {
    this.getAuthAnon();
  },
  computed: {
    ...mapState(['devicePosition', 'isConnected']),
  },
  methods: {
    ...mapActions(['getTwilioAccessToken', 'getAuthAnon', 'getDevicePosition']),
    ...mapMutations(['setIsConnected']),
    getAccessToken() {
      const payload = {
        user: `112.video.${Math.random().toString(36)}`,
        phonenumber: this.phone,
      };

      return this.getTwilioAccessToken({ payload });
    },
    async connectToVideo(phoneNumber) {
      this.isLoading = true;
      this.alertMessage.text = '';
      this.phone = phoneNumber;

      const result = await this.getAccessToken();

      if (result.success && result.data) {
        try {
          this.accessToken = result.data;
          const connectOptions = {
            name: this.phone,
            audio: true,
            video: false,
          };

          this.room = await Twilio.connect(this.accessToken, connectOptions);

          this.unpublishLocalVideo(true);

          this.room.on('disconnected', (room, error) => {
            if (error) {
              this.alertMessage.type = 'error';
              this.alertMessage.text = `Error disconnecting from room (${room}).`;
            }
          });

          if (this.room.participants.size > 0) {
            this.room.participants.forEach((participant) => {
              const container = document.getElementById('remoteTrack');
              participant.on('trackSubscribed', (track) => this.attachTracks([track], container));
              participant.on('trackUnsubscribed', (track) => this.detachTracks([track]));
            });

            this.room.on('trackAdded', (track) => {
              const container = document.getElementById('remoteTrack');
              this.attachTracks([track], container);
            });

            this.room.on('trackRemoved', (track) => {
              this.detachTracks([track]);
            });

            this.room.on('participantConnected', (participant) => {
              const container = document.getElementById('remoteTrack');
              participant.on('trackSubscribed', (track) => this.attachTracks([track], container));
              participant.on('trackUnsubscribed', (track) => this.detachTracks([track]));
            });

            this.room.on('participantDisconnected', () => {
              if (this.room.participants.size < 1) {
                this.leaveRoom();
                this.alertMessage.type = 'warning';
                this.alertMessage.text = 'Room participant left.';
              }
            });
            this.setIsConnected(true);
            this.isLoading = false;
            this.streamData = await this.getDevicePosition(this.phone);
          } else {
            this.alertMessage.type = 'error';
            this.alertMessage.text = 'Room not found.';
            this.leaveRoom();
          }
        } catch (error) {
          this.isLoading = false;
        }
      } else {
        this.alertMessage.type = 'error';
        this.alertMessage.text = 'Cannot get access.';
        this.isLoading = false;
      }
    },
    attachTracks(tracks, container) {
      tracks.forEach((track) => {
        if (track.kind !== 'data') {
          container.appendChild(track.attach());
        }
      });
    },
    detachTracks(tracks) {
      tracks.forEach((track) => {
        if (track.kind !== 'data') {
          track.detach().forEach((detachedElement) => {
            detachedElement.remove();
          });
        }
      });
    },
    unpublishLocalVideo(bool) {
      if (bool) {
        this.room.localParticipant.videoTracks.forEach((publication) => {
          publication.unpublish();
          publication.track.stop();
        });
      }
    },
    toggleAudio(bool) {
      this.isMuted = bool;
      if (bool) {
        this.room.localParticipant.audioTracks.forEach((publication) => {
          publication.track.disable();
        });
      } else {
        this.room.localParticipant.audioTracks.forEach((publication) => {
          publication.track.enable();
        });
      }
    },
    exitFullscreen() {
      if (document.fullscreenElement || document.webkitFullscreenElement
        || document.mozFullScreenElement || document.msFullscreenElement) {
      // Exit fullscreen mode
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
    },
    leaveRoom() {
      if (this.room) {
        this.exitFullscreen();
        this.room.disconnect();
        this.isLoading = false;
        this.setIsConnected(false);
        this.isMuted = false;
        if (this.streamData.unsub) {
          this.streamData.unsub();
        }
      }
    },
  },
};
</script>

<style lang="scss">
.loading {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

.v-alert__content {
  word-break: break-all;
}

video {
  max-width: 100%;
  max-height: 100%;
}
</style>
