<template>
  <v-app id="app">
    <v-main id="mainApp" class="text-center fill-height">
      <bg-slideshow id="w-carousel" />
      <div v-if="!isConnected" id="language" justify="center">
        <language-dropdown />
      </div>
      <div class="fill-height d-flex flex-column justify-center">
        <div v-if="!isConnected">
          <img id="w-logo" src="./assets/logo.png" alt="Logo">
        </div>
        <router-view id="routerView"/>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import LanguageDropdown from './components/LanguageDropdown.vue';
import BgSlideshow from './components/BgSlideshow.vue';

export default {
  name: 'App',
  components: {
    LanguageDropdown,
    BgSlideshow,
  },
  computed: {
    ...mapState(['isConnected']),
  },
};
</script>

<style lang="scss">
#app {
  background-color: #000;
  min-height: 550px;
}
#language {
  position: absolute;
  top: 10px;
  width: 100%;
  min-width: 220px;
}
#w-carousel {
  position: absolute;
  top: 0;
  min-width: 250px;
}
#w-logo {
  position: relative;
  max-width: 250px;
  min-width: 220px;
  width: 100%;
  margin: 0 1em 2em;
}
#routerView {
  min-width: 250px;
}
</style>
