<template>
  <div class="pa-2 mx-2 white d-inline-block">
    <v-select
      id="lang"
      v-model="$i18n.locale"
      :items="languages"
      label="Langue / Language"
      hide-details
      outlined
      dense
    />
  </div>
</template>

<script>
export default {
  name: 'LanguageDropdown',
  data() {
    return {
      languages: [
        { value: 'fr', text: 'Français' },
        { value: 'en', text: 'English' },
        { value: 'es', text: 'Español' },
        { value: 'pt', text: 'Português' },
      ],
      language: 'fr',
    };
  },
};
</script>
