import Vue from 'vue';
import Vuex from 'vuex';
import { httpsCallable } from 'firebase/functions';
import {
  collection,
  doc,
  onSnapshot,
  where,
  query,
  getDocs,
} from 'firebase/firestore';
import { signInAnonymously } from 'firebase/auth';
import { func, db, auth } from '../settings/firebaseInit';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    devicePosition: null,
    isConnected: false,
  },
  mutations: {
    setDevicePosition(state, position) {
      if (position) {
        state.devicePosition = position;
      }
    },
    setIsConnected(state, bool) {
      state.isConnected = bool;
    },
  },
  actions: {
    async getAuthAnon() {
      try {
        const result = await signInAnonymously(auth);
        return { sucess: true, message: 'Successfully authenticated.', data: result };
      } catch (error) {
        return { sucess: false, message: 'Something went wrong.', data: error };
      }
    },
    async getTwilioAccessToken(_, { payload }) {
      try {
        const getAccessToken = httpsCallable(func, 'getTwilioAccessToken');
        const result = await getAccessToken(payload);
        return { success: true, data: result.data };
      } catch (error) {
        return { success: false, data: error.message };
      }
    },
    async getDevicePosition({ commit, dispatch }, phonenumber) {
      try {
        commit('setDevicePosition', null);
        const queryDevices = doc(db, 'streams', phonenumber);
        const unsubDevices = onSnapshot(queryDevices, (docSnapshot) => {
          const stream = docSnapshot.data();
          const { twilioData } = stream;
          dispatch('getPosition', { email: twilioData.ParticipantIdentity });
        });
        return { success: true, unsub: unsubDevices, message: '' };
      } catch (error) {
        return { success: false, unsub: null, message: 'Something went wrong.' };
      }
    },
    async getPosition({ commit }, { email }) {
      try {
        const userQ = query(collection(db, 'users'), where('email', '==', email));
        const userSnapshot = await getDocs(userQ);
        let userRef;
        userSnapshot.forEach((userData) => {
          userRef = userData.ref;
        });
        if (userRef) {
          const deviceQuery = query(collection(db, 'devices'), where('owner', '==', userRef));
          const snapshot = await getDocs(deviceQuery);
          snapshot.forEach((docData) => {
            const deviceData = docData.data();
            const position = {
              lat: deviceData.position.latitude,
              lng: deviceData.position.longitude,
            };
            commit('setDevicePosition', position);
          });
        }
      } catch (e) {
        console.error('error get position', e);
      }
    },
  },
});
